import React from 'react'
import links from 'links'
import cx from 'classnames'
import { Router, usePathname } from 'router'
import { DeviceProvider, useDevice } from 'device'
import { QueryProvider } from 'hooks/query/context'
import { HelmetProvider } from 'react-helmet-async'

import { AuthProvider } from 'containers/AuthProvider'

import { WidthContainer } from 'components/layout'
import { Breadcrumbs } from 'components/navigation'

import Header from 'compositions/Header/Header'
import ServicesSlider from 'compositions/ServicesSlider/ServicesSlider'


// TODO remove titles from these routes in vue:
export const layersWithoutServicesSlider: string[] = [
  links.news.root,
  links.votings.root,
  links.projects.root,
  links.events.root,
  links.appeals.root,
  links.goodsAndServices.root,
  links.sport.root,
  links.edu.root,
  links.privacy,
  links.support,
  links.createRoute,
  links.immortals.root,
  links.transport.root,
  links.medscan.record,
  links.page404,
  links.page403,
  links.noTransport,
  links.statistics,
  links.edu.order.success,
  links.edu.order.fail,
  links.edu.privacy,
]

export const layersWithTitleOnly = [
  links.transport.root,
  links.reactProfile.root,
  links.profile,
  links.medscan.root,
  links.weather,
  links.updatePassword,
  links.notification.root,
  links.edu.order.success,
  links.edu.order.fail,
  links.edu.privacy,
]

const Navigation = () => {
  const { isMobile } = useDevice()

  const pathname = usePathname()
  const isHomePage = pathname === '/'
  const withHeading = !layersWithoutServicesSlider.some((layerLink) => pathname.startsWith(layerLink)) && !isHomePage
  const withServices = !layersWithTitleOnly.some((layerLink) => pathname.startsWith(layerLink)) && !isHomePage

  return withHeading ? (
    <div
      className={cx('flex-auto', isMobile ? 'mt-12px' : 'mt-20px')}
    >
      <WidthContainer>
        <Breadcrumbs />
      </WidthContainer>
      {
        withServices ? (
          <WidthContainer className="mt-24px" ignore={isMobile}>
            <ServicesSlider />
          </WidthContainer>
        ) : (
          <div className="mt-24px" />
        )
      }
    </div>
  ) : (
    <WidthContainer id="breadcrumbs" />
  )
}

const HeaderChunk: React.FC = () => (
  <HelmetProvider>
    <DeviceProvider value={window.__DEVICE__}>
      <QueryProvider value={window.__QUERY_STATE__}>
        <AuthProvider>
          <Router>
            <Header />
            <Navigation />
          </Router>
        </AuthProvider>
      </QueryProvider>
    </DeviceProvider>
  </HelmetProvider>
)


export default HeaderChunk
